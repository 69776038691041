<template>
  <div class="payment-disbursement-transfer-card">
    <bg-card variant="shadow">
      <bg-text
        size="body-1"
        class="payment-disbursement-transfer-card__faded-text"
        >Total Transfer ke Owner bulan {{ period }}</bg-text
      >
      <bg-text size="heading-3">{{
        mixNumberFormatterToRupiah(total)
      }}</bg-text>
    </bg-card>
  </div>
</template>

<script>
import { BgCard, BgText } from 'bangul-vue';
import formatter from '@admin/mixins/formatter';

export default {
  name: 'PaymentDisbursementTransferCard',
  components: {
    BgCard,
    BgText,
  },
  mixins: [formatter],
  props: {
    period: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" src="./PaymentDisbursementTransferCard.scss" scoped />
