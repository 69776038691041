<template>
  <div class="payment-disbursement-approval-filter">
    <bg-grid>
      <bg-grid-item :col="3">
        <bg-datepicker
          v-model="filterDate"
          minimum-view="month"
          maximum-view="month"
          format="MMMM yyyy"
          @input="setFilterDate"
          size="lg"
        />
      </bg-grid-item>
    </bg-grid>
    <bg-grid class="mb-32" :v-align="'center'">
      <bg-grid-item class="mb-0">
        <property-search-bar
          v-model="filterParams.property_name"
          :url="APIPropertySuggestion"
          :placeholder="'Cari Nama Properti'"
          @handle-submit-filter="handleSubmitFilter"
        />
      </bg-grid-item>
      <bg-grid-item class="mb-0">
        <bg-button size="lg" @click="openFilterModal" class="filter-button">
          <bg-badge-counter
            v-if="totalModalFilter"
            :value="totalModalFilter"
            variant="black"
          />
          <bg-icon v-else name="filter" size="sm" />
          <span class="ml-8">Filter</span>
        </bg-button>
        <bg-button size="lg" @click="handleResetFilter" class="ml-16">
          Reset Filter
        </bg-button>
        <bg-button
          size="lg"
          @click="handleSubmitFilter"
          class="ml-16"
          variant="primary"
        >
          Cari
        </bg-button>
      </bg-grid-item>

      <bg-grid-item class="mb-0 ta-r" :col="2">
        <router-link :to="{ name: 'disbursement.add-manual-transfer' }">
          <bg-button class="ml-16" size="lg">Input Manual</bg-button>
        </router-link>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="isModalFilterOpen"
      desktop-size="lg"
      button-main-text="Terapkan"
      button-second-text="Reset"
      @click-main-action="handleSubmitFilter"
      @click-second-action="handleResetModalFilter"
    >
      <div class="mb-24">
        <bg-text tag="h4" size="title-2"
          >Filter Transfer Pendapatan Pemilik</bg-text
        >
        <bg-text size="body-2"> Silakan pilih sesuai kebutuhan anda </bg-text>
      </div>
      <div class="mb-16">
        <bg-text size="title-3">Rentang Jadwal Transfer</bg-text>
      </div>

      <bg-grid>
        <bg-grid-item :col="6">
          <bg-text class="mb-8" size="body-2">Dari tanggal</bg-text>
          <bg-datepicker
            placeholder="Pilih tanggal di sini"
            v-model="startDate"
            @input="handleDatePicker('schedule_min', startDate)"
            :disabled-dates="disabledFromToDate"
          />
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-text class="mb-8" size="body-2">Hingga tanggal</bg-text>
          <bg-datepicker
            placeholder="Pilih tanggal di sini"
            v-model="endDate"
            @input="handleDatePicker('schedule_max', endDate)"
            :disabled-dates="disabledToFromDate"
          />
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">Status Transfer</bg-text>
          <search-checkbox
            name="transferStatus"
            placeholder="Pilih status transfer"
            :list="transferStatus.lists"
            :checked="modalFilterParams.transfer_status"
            :is-loading-list="transferStatus.loading"
            :max-checked="0"
            disable-search
            @emit-checked-array="modalFilterParams.transfer_status = $event"
          />
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">Status Data Pendapatan</bg-text>
          <search-checkbox
            name="transferIncome"
            placeholder="Pilih status data pendapatan"
            :list="incomeStatus.lists"
            :checked="modalFilterParams.status"
            :is-loading-list="incomeStatus.loading"
            :max-checked="0"
            disable-search
            dropdown-menu-placement="bottom-end"
            @emit-checked-array="modalFilterParams.status = $event"
          />
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">AS</bg-text>
          <search-checkbox
            name="accountSupport"
            placeholder="Pilih AS"
            :list="accountSupport.lists"
            :checked="modalFilterParams.account_support"
            :is-loading-list="accountSupport.loading"
            :max-checked="0"
            disable-search
            dropdown-menu-placement="bottom-end"
            @emit-checked-array="modalFilterParams.account_support = $event"
            @dropdown-open="fetchOfficerList"
          />
        </bg-grid-item>
      </bg-grid>
    </bg-modal>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgDatepicker,
  BgModal,
  BgBadgeCounter,
  BgIcon,
  BgText,
} from 'bangul-vue';
import EventBus from 'Utils/event-bus';
import PropertySearchBar from '@admin_molecules/PropertySearchBar';
import formatter from '@admin/mixins/formatter';
import homepageApi from '@admin_endpoints/homepage';
import dayjs from 'dayjs';

export default {
  name: 'PaymentDisbursementApprovalFilter',
  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgDatepicker,
    BgModal,
    BgBadgeCounter,
    BgIcon,
    BgText,
    PropertySearchBar,
    SearchCheckbox: () => import('@admin_molecules/SearchCheckbox'),
  },

  mixins: [formatter],

  data() {
    return {
      filterName: '',
      filterDate: new Date(),
      isFilterStatusOpen: false,
      isModalFilterOpen: false,
      startDate: null,
      endDate: null,
      filterParams: {
        property_name: '',
      },
      modalFilterParams: {
        transfer_status: [],
        status: [],
        account_support: [],
        schedule_min: '',
        schedule_max: '',
      },
      APIPropertySuggestion:
        '/singgahsini/api/income/approval-property-disbursement/properties-suggestion',
      transferStatus: {
        loading: false,
        lists: [
          { label: 'Waiting List', value: 'waiting' },
          { label: 'Processing', value: 'processing' },
          { label: 'Failed', value: 'failed' },
          { label: 'Transferred', value: 'transferred' },
        ],
      },
      incomeStatus: {
        loading: false,
        lists: [
          { label: 'Dikonfirmasi', value: 'approved' },
          { label: 'Menunggu Konfirmasi', value: 'unapproved' },
        ],
      },
      accountSupport: {
        loading: false,
        lists: [],
      },
    };
  },

  computed: {
    // This is getting confusing, use 'from' value for from date input
    disabledFromToDate() {
      const date = this.filterDate;
      const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDate = null;

      if (this.endDate) {
        lastDate = new Date(
          this.endDate.getFullYear(),
          this.endDate.getMonth(),
          this.endDate.getDate()
        );
      } else {
        lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      return {
        to: firstDate,
        from: lastDate,
      };
    },

    disabledToFromDate() {
      const date = this.filterDate;
      const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let firstDate = null;

      if (this.startDate) {
        firstDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate()
        );
      } else {
        firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      }

      return {
        to: firstDate,
        from: lastDate,
      };
    },
    totalModalFilter() {
      let total = 0;
      const values = Object.values(this.modalFilterParams);
      values.forEach(value => {
        if (value.length) total += 1;
      });
      return total;
    },
  },

  created() {
    if (sessionStorage.getItem('filterParams')) {
      this.filterParams = JSON.parse(sessionStorage.getItem('filterParams'));

      if (this.filterParams.month && this.filterParams.year) {
        this.filterDate = new Date(
          `${this.filterParams.month}-01-${this.filterParams.year}`
        );
      }
    }

    if (sessionStorage.getItem('modalFilterParams')) {
      this.modalFilterParams = JSON.parse(
        sessionStorage.getItem('modalFilterParams')
      );

      if (this.modalFilterParams.schedule_min) {
        this.startDate = dayjs(
          this.modalFilterParams.schedule_min,
          'YYYY-MM-DD'
        ).toDate();
      }

      if (this.modalFilterParams.schedule_max) {
        this.endDate = dayjs(
          this.modalFilterParams.schedule_max,
          'YYYY-MM-DD'
        ).toDate();
      }
    }
  },

  methods: {
    handleSubmitFilter() {
      sessionStorage.setItem('filterParams', JSON.stringify(this.filterParams));
      sessionStorage.setItem(
        'modalFilterParams',
        JSON.stringify(this.modalFilterParams)
      );
      EventBus.$emit('onSubmitFilter', {
        ...this.filterParams,
        ...this.modalFilterParams,
      });
    },
    handleResetFilter() {
      this.filterParams = {
        property_name: '',
      };
      this.handleResetModalFilter();
    },
    handleResetModalFilter() {
      this.modalFilterParams = {
        transfer_status: [],
        status: [],
        account_support: [],
        schedule_min: '',
        schedule_max: '',
      };
      this.handleSubmitFilter();
    },
    handleDatePicker(dateType, date) {
      const dateYear = date.getFullYear();
      const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
      const dateDay = `0${date.getDate()}`.slice(-2);
      this.modalFilterParams[dateType] = `${dateYear}-${dateMonth}-${dateDay}`;
    },
    setFilterDate() {
      const filterYear = dayjs(this.filterDate).format('YYYY');
      const filterMonth = dayjs(this.filterDate).format('MM');

      this.filterParams.month = filterMonth;
      this.filterParams.year = filterYear;

      this.startDate = new Date(
        this.filterDate.getFullYear(),
        this.filterDate.getMonth(),
        1
      );
      this.endDate = new Date(
        this.filterDate.getFullYear(),
        this.filterDate.getMonth() + 1,
        0
      );

      this.handleDatePicker('schedule_min', this.startDate);
      this.handleDatePicker('schedule_max', this.endDate);
    },
    openFilterModal() {
      this.isModalFilterOpen = true;
    },

    async fetchOfficerList() {
      const needFetchData = !this.accountSupport.lists.length;

      const mappingData = datas =>
        !datas ? [] : datas.map(data => ({ label: data, value: data }));

      if (!this.accountSupport.loading && needFetchData) {
        this.accountSupport.loading = true;

        try {
          const { data } = await homepageApi.getFilterOfficer();

          if (data.status) {
            this.accountSupport.lists = mappingData(
              data.data.account_support_name
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.accountSupport.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./PaymentDisbursementApprovalFilter.scss" scoped />
